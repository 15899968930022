/**
 * videoBlock.js
 * @author: Cody Marcoux (Studio123)
 */

const initVideoBlock = () => {
  if ($('.section--video_block').length) {
    $('.player').each((i, e) => {
      const player = new Plyr(e);
    });
  }
}