/**
 * eventIndex.js
 * @author: Cody Marcoux (Studio123)
 */

const initEventIndex = () => {
  if ($('.section--event_index').length) {
    let element = $('.index-container')[0];
    let offset = $('header').outerHeight();
    var waypoint = new Waypoint({
      element: element,
      handler: function(direction) {
        if (direction == "down") {
          $('.filter').addClass('active');
        } else {
          $('.filter').removeClass('active');
        }
      },
      offset: offset
    });

    $('.event h3').matchHeight({
      byRow: true
    });

    $('.filter select').selectric();

    $('.filter select').on('change', function() {
      let cat = $(this).find(':selected').attr('data-category');
      console.log(cat);
      if (cat == "*") {
        $('.event').removeClass('hide');
      } else {
        $('.event').removeClass('hide');
        $('.event').not('[data-category="' + cat + '"]').addClass('hide');
      }
    });
  }
}