/**
 * forms.js
 * @author Cody Marcoux (Studio123)
 */

const initForms = () => {
  if ($('.gform_wrapper').length) {
    $('.gform_wrapper').each(function(i, e) {

      // Re-evaluate GravityForms javascript
      let formScript = $(this).parents('.form').find('script').text();

      eval(formScript);

    });
  }
}