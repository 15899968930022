/**
 * mainMenu.js
 * @author: Cody Marcoux (Studio123)
 */

let menuButton = $('.menu-button');
let menuDuration = 0.3;
let menuEasing = "power1.easeOut";
let menuIsAnimating = false;

let clipClosedTop = 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)';
let clipClosedBottom = 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)';
let clipOpen = 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)';

let liquidInstances = [];

waitForWebfonts(['Suisse Intl'], function() {
  $('body').addClass('fonts-loaded');
  $('nav ul.menu li a').each(function(i, e) {
    var liquidText = new LiquidText(e, 0.025, 0.1);
    liquidInstances.push(liquidText);
  });
});

const mainMenuOpen = gsap.timeline({
  paused: true,
  onStart: () => {
    $('.menu-button, nav').addClass('is-active');
    menuIsAnimating = true;
    $(liquidInstances).each(function(i, e) {
      e.start();
    });
  },
  onComplete: () => {
    menuIsAnimating = false;
  }
});
mainMenuOpen.to('nav', menuDuration, {
  ease: "power2.easeIn",
  webkitClipPath: clipOpen,
  clipPath: clipOpen
}, 0);
mainMenuOpen.to('nav ul.menu li a', menuDuration, {
  ease: "power2.easeIn",
  translateY: '0',
  opacity: '1'
}, 0);
mainMenuOpen.to('nav hr', menuDuration, {
  ease: "power2.easeIn",
  scaleX: '1',
}, 0);

const mainMenuClose = gsap.timeline({
  paused: true,
  onStart: () => {
    $(liquidInstances).each(function(i, e) {
      e.stop();
    });
    $('.menu-button, nav').removeClass('is-active');
    menuIsAnimating = true;
  },
  onComplete: () => {
    menuIsAnimating = false;

  }
});
mainMenuClose.to('nav ul.menu li a', menuDuration, {
  ease: "power2.easeOut",
  translateY: '100%',
  opacity: '0',
}, 0);
mainMenuClose.to('nav hr', menuDuration, {
  ease: "power2.easeOut",
  scaleX: '0',
}, 0);
mainMenuClose.to('nav', menuDuration, {
  ease: "power2.easeOut",
  webkitClipPath: clipClosedTop,
  clipPath: clipClosedTop
}, 0);

const toggleMenu = (anim, duration, ease) => {
  const masterTimeline = gsap.fromTo(anim,
    duration, {
      progress: 0
    }, {
      ease: ease,
      progress: 1
    });
}

// Toggle menu
menuButton.on('click', function() {
  if (menuIsAnimating == false) {
    if (menuButton.hasClass('is-active')) {
      toggleMenu(mainMenuClose, 0.8, "power1.easeOut");
    } else {
      toggleMenu(mainMenuOpen, 0.8, "power1.easeIn");
    }
  }
});

$('nav ul li a, header a').on('click', function() {
  if (menuButton.hasClass('is-active')) {
    toggleMenu(mainMenuClose, 0.8, "power1.easeOut");
  }
});