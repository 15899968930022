//-----------------------------------
// scripts.js
// Author: @codymarcoux (Studio123)
//
// NOTE: Main scripts file
//-----------------------------------

const initModules = () => {
  initVideoPlayer();
  initPhotoGallery();
  initVideoBlock();
  initEventIndex();
  initTicketBlock();
  initBlog();
  initForms();
}

initModules();

const initLiquid = () => {
  $('.liquid-image').each((i, e) => {
    new LiquidImage(e);
  });
}

barba.hooks.before((data) => {});

barba.hooks.beforeLeave((data) => {

});

barba.hooks.enter((data) => {
  if (typeof ga === 'function') {
    ga('send', 'pageview', location.pathname);
  }

  if (typeof fbq === 'function') {
    fbq("track", "PageView", location.pathname);
  }

  initModules();
});

barba.hooks.afterEnter((data) => {
  // initLiquid();
});

barba.hooks.after((data) => {});

// Init Barba
if (window.barba) {
  barba.init({
    debug: true,
    prefetchIgnore: true,
    logLevel: 3,
    timeout: 5000,
    transitions: fadeTransition
  });
} else {
  console.warn('Barba is not found. The site will work normally, but AJAX navigation is disabled.');
}