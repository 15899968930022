/**
 * fixVhUnit.js
 * @author Cody Marcoux (Studio123)
 */

// Fix vh units on mobile browsers
var vh = window.innerHeight * 0.01;
var vhMenu = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', "".concat(vh, "px"));
document.documentElement.style.setProperty('--vhMenu', "".concat(vhMenu, "px"));
window.addEventListener('resize', function() {
  var vhMenu = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vhMenu', "".concat(vhMenu, "px"));
  if ($(window).width() >= 769) {
    var _vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', "".concat(_vh, "px"));
  }
});