/**
 * blogAjax.js
 * @author Cody Marcoux (Studio123)
 */

const initBlog = () => {
  if ($('.section--blog_index').length) {
    $('a.view-more').on('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();

      $(this).addClass('active');

      let args = JSON.stringify($(this).data('query'));
      let paged = parseInt($(this).attr('data-page'));
      let maxPages = parseInt($(this).attr('data-max-pages'));

      blogQuery(args, paged, maxPages, $(this));
      $(this).attr('data-page', paged + 1);
    });
  }

  if ($('.section--blog_index').length) {
    $('.section--blog_index h5:not(.date)').matchHeight({
      byRow: true
    });
  }
}

const blogQuery = (args, paged, maxPages, buttonElem) => {
  $.ajax({
    url: '/wp-admin/admin-ajax.php',
    type: 'POST',
    data: {
      action: 'post_query',
      args: args,
      paged: paged
    },
  }).done(function(data) {
    $('.posts .columns').append(data);

    buttonElem.removeClass('active');

    if (maxPages <= paged) {
      buttonElem.remove();
    }

    if ($('.section--blog_index').length) {
      $('.section--blog_index h5:not(.date)').matchHeight({
        byRow: true
      });
    }

  });
}