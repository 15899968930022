/**
 * liquidImage.js
 * @author Cody Marcoux (Studio123)
 */

class LiquidImage {
  constructor(element, animateOnScroll) {
    this.element = element;
    this.width = element.offsetWidth;
    this.height = element.offsetHeight;
    this.position = $(element).attr('data-img-pos');
    this.img = {
      url: $(element).data('img-url'),
      width: 0,
      height: 0,
      position: {
        xPercent: this.position ? parseInt(this.position.split(" ")[0]) : 50,
        yPercent: this.position ? parseInt(this.position.split(" ")[1]) : 50
      }
    };
    this.displacement = {
      url: '/wp-content/themes/avantage-numerique/img/displacement/noise_disp.jpg',
      scaleX: 3,
      scaleY: 3,
      sprite: null,
      filter: null
    }
    this.app = new PIXI.Application({
      width: this.width,
      height: this.height,
      resizeTo: this.element,
      transparent: true,
      autoDensity: true,
      resolution: window.devicePixelRatio,
      antialiasing: true,
      forceFXAA: false,
      powerPreference: 'high-performance'
    });
    this.sprite = null;

    // This option can be gpu intensive if there are lots of liquid image instances on the page, use with caution!
    this.animateOnScroll = animateOnScroll ? animateOnScroll : false;

    this.onResize = this.onResize.bind(this);
    this.onScroll = this.onScroll.bind(this);

    this.initialize();
  }
  initialize() {

    if (!this.img.url) {
      console.warn("No image could be found to liquify. Are you sure you're passing a valid url using the 'data-img-url' attribute?");
      return false;
    };

    let instance = this;
    instance.element.appendChild(instance.app.view);

    let renderer = instance.app.renderer;
    let element = instance.element;
    let origWidth, origHeight;
    renderer.autoDensity = true;

    const loader = new PIXI.Loader();
    loader.add('image', instance.img.url);

    loader.load((loader, resources) => {

      origWidth = resources.image.data.width;
      origHeight = resources.image.data.height;

      instance.sprite = new PIXI.Sprite(resources.image.texture);
      const displacementSprite = PIXI.Sprite.from(instance.displacement.url);
      // Make sure the sprite is wrapping.
      displacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;
      const displacementFilter = new PIXI.filters.DisplacementFilter(displacementSprite);
      displacementFilter.padding = 10;

      displacementSprite.position = instance.sprite.position;

      instance.app.stage.addChild(displacementSprite);

      instance.sprite.filters = [displacementFilter];

      this.displacement.sprite = displacementSprite;
      this.displacement.filter = displacementFilter;

      displacementFilter.scale.x = 0;
      displacementFilter.scale.y = 0;
    });

    loader.onComplete.add(() => {

      window.addEventListener('resize', this.onResize);

      if (instance.animateOnScroll === true) {
        window.addEventListener('scroll', this.onScroll);
      }

      gsap.to(this.displacement.sprite, 30, {
        ease: Linear.easeNone,
        repeat: -1,
        x: 512,
        y: 512
      });

      instance.img.width = origWidth;
      instance.img.height = origHeight;
      const container = new PIXI.Container();
      instance.app.stage.addChild(container);
      container.addChild(instance.sprite);

      let containerWidth = instance.width;
      let containerHeight = instance.height;

      let width, height, ratio;
      if (instance.height < instance.width) {
        // Landscape
        ratio = origWidth / origHeight;
        width = instance.width;
        height = instance.width / ratio;
        if (width < containerWidth) {
          width = instance.width;
          height = instance.width * ratio;
        }
        if (height < containerHeight) {
          width = containerHeight * ratio;
          height = containerHeight;
        }
        instance.app.view.style.transform = "translate(-50%, -" + instance.img.position.yPercent + "%)";
      } else {
        // Portrait
        ratio = origHeight / origWidth;
        width = instance.height / ratio;
        height = instance.height;
        if (height < containerHeight) {
          width = instance.height * ratio;
          height = instance.height;
        }
        if (width < containerWidth) {
          width = instance.width;
          height = instance.width * ratio;
        }
        instance.app.view.style.transform = "translate(-" + instance.img.position.xPercent + "%, -50%)";
      }

      instance.sprite.width = width;
      instance.sprite.height = height;
      instance.app.renderer.resize(width, height);

      // instance.sprite.x = (containerWidth / 2) + (-width / 2);
      // instance.sprite.y = (containerHeight / 2) + (-height / 2);

    });

    let parentAnchor = $(instance.element).parents('a');
    let elem = instance.element;

    if (parentAnchor.length > 0) {
      elem = parentAnchor[0];
    } else {
      elem = instance.element;
    }
    if (instance.animateOnScroll === false) {
      elem.onmouseenter = (event) => {
        gsap.to(this.displacement.filter.scale, 0.5, {
          x: this.displacement.scaleX,
          y: this.displacement.scaleY
        });
      }

      elem.onmouseleave = (event) => {
        gsap.to(this.displacement.filter.scale, 0.5, {
          x: 0,
          y: 0
        });
      }

      elem.onmousedown = (event) => {
        gsap.to(this.displacement.filter.scale, 0.5, {
          x: this.displacement.scaleX * 2,
          y: this.displacement.scaleY * 2
        });
      }

      elem.onmouseup = (event) => {
        gsap.to(this.displacement.filter.scale, 0.1, {
          x: this.displacement.scaleX,
          y: this.displacement.scaleY
        });
      }
    }
  }
  onScroll(event) {
    let instance = this;
    let debounceTimer;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(function() {
      let state = tornis.getViewportState(); // Use Tornis to get scroll velocity
      let scrollVelocity = Math.abs(state.scroll.velocity.y) / 1.5;

      if (scrollVelocity > (instance.displacement.scaleX * 3)) {
        scrollVelocity = instance.displacement.scaleX * 3;
      }

      if (scrollVelocity >= 1) {
        gsap.to(instance.displacement.filter.scale, 0.5, {
          x: scrollVelocity,
          y: scrollVelocity,
          onComplete: () => {
            instance.onScroll();
          }
        });
      } else {
        gsap.to(instance.displacement.filter.scale, 0.5, {
          x: 0,
          y: 0
        });
      }
    }, 400);
  }
  onResize(event) {
    let instance = this;
    let containerWidth = $(instance.element).width();
    let containerHeight = $(instance.element).height();

    let resizeTimer;

    clearTimeout(resizeTimer);

    resizeTimer = setTimeout(function() {
      let width, height, ratio;
      if (containerHeight < containerWidth) {
        // Landscape
        ratio = instance.img.width / instance.img.height;
        width = containerWidth;
        height = containerWidth / ratio;
        if (width < containerWidth) {
          width = containerWidth;
          height = containerWidth * ratio;
        }
        if (height < containerHeight) {
          width = containerHeight * ratio;
          height = containerHeight;
        }
        instance.app.view.style.transform = "translate(-50%, -" + instance.img.position.yPercent + "%)";
      } else {
        // Portrait
        ratio = instance.img.height / instance.img.width;
        width = containerHeight / ratio;
        height = containerHeight;
        if (height < containerHeight) {
          width = containerHeight * ratio;
          height = containerHeight;
        }
        if (width < containerWidth) {
          width = containerWidth;
          height = containerWidth * ratio;
        }
        instance.app.view.style.transform = "translate(-" + instance.img.position.xPercent + "%, -50%)";
      }
      // instance.sprite.x = (containerWidth / 2) + (-width / 2);
      instance.sprite.width = width;
      instance.sprite.height = height;

      instance.width = containerWidth;
      instance.height = containerHeight;
      instance.app.renderer.resize(width, height);
    }, 400);
  }
}