/**
 * videoModals.js
 * @author: Cody Marcoux (Studio123)
 */

const videoPlayer = new Plyr('#player');
const videoModal = $('#video-modal');

const initVideoPlayer = () => {
  $('.video-callout').on('click', function(e) {

    e.preventDefault();
    videoModal.addClass('active');
    $('body').addClass('is-locked');

    let videoProvider = $(this).attr('data-video-provider');
    let videoId = $(this).attr('data-video-id');
    let posterImage = $(this).attr('data-img-url');

    videoPlayer.source = {
      type: 'video',
      poster: posterImage,
      sources: [{
        src: videoId,
        provider: videoProvider,
      }],
    };

    setTimeout(function() {
      videoPlayer.play();
    }, 1000);

  });

  $('#video-modal .close, #video-modal .underlay').on('click', function(e) {
    e.preventDefault();
    if (videoModal.hasClass('active')) {
      videoPlayer.stop();
      videoModal.removeClass('active');
      $('body').removeClass('is-locked');
    }
  });
}